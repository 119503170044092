import { defineStore } from 'pinia';
import axios from 'Axios';

export const soundStore = defineStore('soundStore', {
    state: () => {
        return {
            loaded: false,
            klass: 'fadeIn',
            song: {}
        }
    },
    actions: {
        loadCurrentSong: async function () {  
            this.klass = 'fadeOut'; 
                   
            return await axios.post('../../../../loadCurrentSong.php').then((resp) => {
                let timeOut = null;

                if(resp.status === 200 && resp.data.title){
                    this.song = resp.data;

                    if(this.song.bgColor1){
                        document.documentElement.style.setProperty("--bg-color-1", this.song.bgColor1);
                    }
            
                    if(this.song.bgColor2){
                        document.documentElement.style.setProperty("--bg-color-2", this.song.bgColor2);
                    }

                    timeOut = (resp.data.remainingTimeMs * 1000);

                    this.klass = 'fadeIn';
                    this.loaded = true;
                }
            })
        },
    }
});