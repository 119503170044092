import { createApp } from 'vue';
import { io } from "socket.io-client";

import VueSocketIOExt from 'vue-socket.io-extended';
import OysterApp from '../views/OysterApp.vue';

export default function loadApp(el, pinia) {
    const app = createApp(OysterApp);

    if(pinia){
        app.use(pinia);
    }
    
    // lets try and add the socket if it exists
    const socket = io("https://signage.jandcoyster.com:443", {
        path: '/webhook',
        reconnection: true,
        rejectUnauthorized: false,
        transports: [ "websocket", "polling"],
    });

    socket.on("connect_error", (err) => {
        // the reason of the error, for example "xhr poll error"
        console.log(err);
        
        // some additional description, for example the status code of the initial HTTP response
        console.log(err.description);
        
        // some additional context, for example the XMLHttpRequest object
        console.log(err.context);
    });

    socket.on('connect', function(socket) { 
        console.log('Connected!', socket);
    });

    app.use(VueSocketIOExt, socket);

    app.mount(el);
}
