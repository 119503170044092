import { createPinia } from 'pinia'

import JCOysterApp from '../vue/apps/OysterApp';

const pinia = createPinia();

document.addEventListener("DOMContentLoaded", function(event) { 
    const jcApp = document.getElementById('jcApp');
    
    if(jcApp){
        new JCOysterApp(jcApp, pinia);
    }
});
